import React from "react";
import BaseContainer from "core/BaseContainer/";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import * as actionTypes from 'app/store/action/';

class Recaptcha extends BaseContainer {
  constructor(props) {
    super(props);
    this.state = {
      callback: "not fired",
      load: false,
    };
    this._reCaptchaRef = React.createRef();
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    });
  }
  RecaptchaonChange = (recaptcha_token) => {
    this.props.isVerified(recaptcha_token);
  };

  render() {  
    const platformConfig = this.props.platformConfig.web;
    const siteKey_config = platformConfig["1.0"].sitekey; 
    console.log( "recaptcha keyy",siteKey_config);        
    const { load } = this.state || {};

    return (
      <div className="Recaptcha">
        {load && (
          <ReCAPTCHA
            ref={this._reCaptchaRef}
            sitekey={siteKey_config}
            onChange={this.RecaptchaonChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    platformConfig: state.platformConfig,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {    
    fnFetchPlatformConfig: (sLanguageCode, sCountry, fnSuccess, fnFailure) => {
      dispatch(actionTypes.fnFetchPlatformConfig(sLanguageCode, sCountry, fnSuccess, fnFailure));
        },              
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recaptcha);
