/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actionTypes from "app/store/action/";
import * as CONSTANTS from "app/AppConfig/constants";
import oResourceBundle from "app/i18n/";
import * as common from "app/utility/common";
import Logger from "core/Logger";
import Moviesimg from "../../../resources/assets/newslider/Movies.svg";
import LiveTvimg from "../../../resources/assets/newslider/Live TV.svg";
import Programsimg from "../../../resources/assets/newslider/Programs.svg";
import Seriesimg from "../../../resources/assets/newslider/Series.svg";
import Playlistimg from "../../../resources/assets/newslider/Playlist.svg";
import MoreDetailsIcon from "../../../resources/assets/newslider/moreButton.svg";
import { ENABLE_BANNER_ADVERTISEMENT } from "app/AppConfig/features";
import "./index.scss";
import { isMobile, isTablet } from "react-device-detect";
import axios from "axios";
import {
  fnNavTo,
} from "app/utility/common";
import * as appURLs from "app/AppConfig/urlConfig";
import { useNavigate, useHistory  } from 'react-router-dom';
const MODULE_NAME = "SmartTvLayout";

class SmartTvLayoutD extends React.PureComponent {

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      imgs: [],
      homePageD: {
        value: "",
        title: "",
        synopsis: "",
        contentTypeImg: "",
        friendly_url: "",
        seasons: "",
        episodes: "",
        hasSeasonLogo: "",
        contentId: "",
        digitalRights: "",
        WatchTrailer: "",
        videoId: "",
      },
      seasonLogoDimensions: { width: 0, height: 0 },
      clickedIndex: 0,
      backdropImages: props.imgs || [],
      mainBackdrop: "",
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      mobileView: window.innerWidth < CONSTANTS.MOBILE_VIEW_THRESHOLD,
      mobileCarousel: [],
      desktopCarouselLeft: [],
      desktopCarouselRight: [],
      
    };
    this.autoplayInterval = null;
    this.playButtonClick = this.playButtonClick.bind(this);
    this.moreButtonClick = this.moreButtonClick.bind(this);
    this.onClickTrialers = this.onClickTrialers.bind(this);
  }
  

  /**
   * Component Name - SmartTvLayout
   * It executes after component mounted
   * @param null
   * @returns { undefined }
   */
  getAspectRatio(imageSize) {  
          const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 768 && screenWidth <= 1024;
    const isMobileView = screenWidth <= 767
    const originalWidth = imageSize.width;
    const originalHeight = imageSize.height;
    // const maxHeight = isMobileView ?  50 : 100
    const maxHeight = isMobileView ? 50  : 100;    
    let newWidth;
    let newHeight;
    if (originalHeight > maxHeight) {
      const aspectRatio = originalWidth / originalHeight;      
      newHeight = maxHeight;
      newWidth = aspectRatio * newHeight;
    } else {
      newWidth = originalWidth;
      newHeight = originalHeight;
    }
    return { width: newWidth, height: newHeight };
  }
  handleLogoLoad = (event) => {
    const width = event.target.naturalWidth;
    const height = event.target.naturalHeight;
    // console.log("Actual width:", width, "Actual height:", height);
    const newDimensions = this.getAspectRatio({ width, height });
    // console.log("New Dimensions:", newDimensions);
    this.setState({ seasonLogoDimensions: newDimensions });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.initializeAutoplay();
    this.startAutoplay();
    const contentList = this.props && this.props.playListData[0].content;
    const thumbnails = contentList.map((item) => ({
      image: item.imagery.backdrop,
      title: item.title,
      synopsis: item.synopsis,
      contentTypeImg: item.content_type,
      seasons: item.seasons,
      episodes: item.episodes,
      friendly_url: item.friendly_url,
      has_season_logo: item.imagery.has_season_logo,
      contentId: item.id,
      WatchTrailer: item.HasEpisode,
      digitalRights: item.digitalRighttype,
      videoId: item.video_id,
    }));

    this.setState({
      homePageD: {
        value: thumbnails[0].image,
        title: thumbnails[0].title,
        synopsis: thumbnails[0].synopsis,
        contentTypeImg: thumbnails[0].contentTypeImg,
        hasSeasonLogo: thumbnails[0].has_season_logo,
        contentId: thumbnails[0].contentId,
        WatchTrailer: thumbnails[0].WatchTrailer,
        digitalRights: thumbnails[0].digitalRights,
        videoId: thumbnails[0].videoId,
      },
      imgs: thumbnails.map((item) => item.image),
      backdropImages: thumbnails,
      mainBackdrop: thumbnails[0].image,
      
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      console.log('Location changed:', this.props.location.pathname);
    }
    if (prevProps.playListData !== this.props.playListData) {
      const contentList = this.props.playListData[0].content;
      const thumbnails = contentList.map((item) => ({
        image: item.imagery.backdrop,
        title: item.title,
        synopsis: item.synopsis,
        contentTypeImg: item.content_type,
        seasons: item.seasons,
        episodes: item.episodes,
        friendly_url: item.friendly_url,
        has_season_logo: item.imagery.has_season_logo,
        contentId: item.id,
        WatchTrailer: item.HasEpisode,
        digitalRights: item.digitalRighttype,
        videoId: item.video_id,
      }));

      if (thumbnails[0].synopsis !== prevState.homePageD.synopsis) {
        this.setState({
          homePageD: {
            value: thumbnails[0].image,
            title: thumbnails[0].title,
            synopsis: thumbnails[0].synopsis,
            contentTypeImg: thumbnails[0].contentTypeImg,
            hasSeasonLogo: thumbnails[0].has_season_logo,
            contentId: thumbnails[0].contentId,
            WatchTrailer: thumbnails[0].WatchTrailer,
            digitalRights: thumbnails[0].digitalRights,
            videoId: thumbnails[0].videoId,
          },
          imgs: thumbnails.map((item) => item.image),
          backdropImages: thumbnails,
          mainBackdrop: thumbnails[0].image,
        });
      }
    }

    if (
      prevState.mobileView !== this.state.mobileView ||
      prevProps.isUserSubscribed !== this.props.isUserSubscribed
    ) {
    }  
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.stopAutoplay();
    clearInterval(this.autoplayInterval);
  }
  initializeAutoplay = () => {
    if (this.state.backdropImages.length > 0 && !this.autoplayInterval) {
      this.startAutoplay();
    }
  };
  startAutoplay = () => {
    this.autoplayInterval = setInterval(() => {
      const nextIndex =
        (this.state.clickedIndex + 1) % this.state.backdropImages.length;
      this.updateImageData(nextIndex);
    }, 5000);
  };

  stopAutoplay = () => {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
    }
  };
  updateImageData = (index) => {
    const selectedImage = this.state.backdropImages[index];
    this.setState({
      clickedIndex: index,
      homePageD: {
        ...this.state.homePageD,
        value: selectedImage.image,
        title: selectedImage.title,
        synopsis: selectedImage.synopsis,
        contentTypeImg: selectedImage.contentTypeImg,
        hasSeasonLogo: selectedImage.has_season_logo,
        friendly_url: selectedImage.friendly_url,
        seasons: selectedImage.seasons,
        contentId: selectedImage.contentId,
        WatchTrailer: selectedImage.WatchTrailer,
        digitalRights: selectedImage.digitalRights,
        videoId: selectedImage.videoId,
      },
    });
  };

  handleClick = (index) => {
    this.stopAutoplay();
    this.updateImageData(index);
    this.startAutoplay();
  };
  moreButtonClick() {
    const title = this.state.homePageD.title.replace(/ +/g, "-").toLowerCase();
    const sLinkPath = `/${this.props.locale}/${
      this.state.homePageD.contentTypeImg
    }/${this.state.homePageD.contentId}/${decodeURI(title.replace(/-+$/, ""))}`;
    this.props.history.push(sLinkPath);
  }

playButtonClick(event) {
  event.preventDefault(); 
  const { contentTypeImg, contentId, digitalRights, title } = this.state.homePageD;
  const { sCountryCode, locale, isUserSubscribed, history } = this.props;

  // Generate API URL based on contentTypeImg
  const apicdUrl =
    contentTypeImg === "movie"
      ? `${appURLs.WatchNOW}/v1/en/contents/moviedetails?Country=${sCountryCode}&contentkey=${contentId}`
      : `${appURLs.WatchNOW}/v1/en/series?contentkey=${contentId}&cascade=3&country=${sCountryCode}`;

  fetch(apicdUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      if (
        contentTypeImg === "movie" ||
        contentTypeImg === "livetv" ||
        contentTypeImg === "play" ||
        contentTypeImg === "series" ||
        contentTypeImg === "program"
      ) {
        const isSeriesOrProgram =
          contentTypeImg === "series" || contentTypeImg === "program";
        let sPlayerPath = "";
      
        if (isSeriesOrProgram) {
          // Handle series or program logic
          const seriesData = data.data || data;
          if (
            seriesData &&
            Array.isArray(seriesData.seasons) &&
            seriesData.seasons.length > 0
          ) {
            const season = seriesData.seasons[0];
            if (Array.isArray(season.episodes) && season.episodes.length > 0) {
              const episodeId = season.episodes[0].id;
              const seriesDigitalRights = season.episodes[0].digitalRighttype;
      
              if (!common.isUserLoggedIn() && seriesDigitalRights === 3) {
                const player_path = `/${this.props.locale}/${CONSTANTS.LOGIN}`;
                this.props.history.push(player_path);
                return;
              }
      
              if (seriesDigitalRights === 3 && !isUserSubscribed) {
                const player_path = `/${this.props.locale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH}`;
                this.props.history.push(player_path);
                return;
              }
      
              sPlayerPath = `/${locale}/${CONSTANTS.PLAYER}/${CONSTANTS.EPISODE}/${episodeId}/${decodeURI(
                title.replace(/ +/g, "-").replace(/-+$/, "").toLowerCase()
              )}-${oResourceBundle.episode.toLowerCase()}-${season.season_number}`;
            }
          }
        } else {
          // Handle movie, livetv, or play logic
          const MoviesDigitalRights = data.data.movies[0].digitalRighttype;
      
          if (!common.isUserLoggedIn() && MoviesDigitalRights === 3) {
            const player_path = `/${this.props.locale}/${CONSTANTS.LOGIN}`;
            this.props.history.push(player_path);
            return;
          }
      
          if (MoviesDigitalRights === 3 && !isUserSubscribed) {
            const player_path = `/${this.props.locale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH}`;
            this.props.history.push(player_path);
            return;
          }
      
          sPlayerPath = `/${locale}/${CONSTANTS.PLAYER}/${contentTypeImg}/${contentId}/${decodeURI(
            title.replace(/ +/g, "-").replace(/-+$/, "").toLowerCase()
          )}`;
        }
      
        // Push the player path if defined
        if (sPlayerPath) {
          this.props.history.push(sPlayerPath);
        }
      }
      
      
    })
    .catch((error) => {
      console.error("Error fetching API data:", error);
    });
}  

  onClickTrialers() {
    const title = this.state.homePageD.title.replace(/ +/g, "-").toLowerCase().replace(/-+$/, "");
    const contentId = this.state.homePageD.contentId;
    const apiUrl1 = `${appURLs.WatchNOW}/v1/en/${this.state.homePageD.contentTypeImg}?contentkey=${contentId}&cascade=3&country=${this.props.sCountryCode}`;
  
    axios
      .get(apiUrl1)
      .then((response) => {
  
        if (response.data.data.seasons && response.data.data.seasons.length > 0) {
          const seasonId = response.data.data.seasons[0].id; 
  
          if (!seasonId) {
            throw new Error("Season ID not found");
          }
  
          const apiUrl2 = `${appURLs.WatchNOW}/v1/en/contents/contentTrailer?Country=IN&contenttype=series&contentkey=${seasonId}`;
  
          return axios.get(apiUrl2);
        } else {
          throw new Error("Seasons array is empty or not present");
        }
      })
      .then((response) => {
  
        if (Array.isArray(response.data) && response.data.length > 0) {
          const videoId = response.data[0].video_id;
  
          if (!videoId) {
            throw new Error("Video ID not found in the response");
          }
  
          const sTrailerPath = `/${this.props.locale}/${CONSTANTS.PLAYER}/${
            this.state.homePageD.contentTypeImg
          }/${this.state.homePageD.contentId}/${decodeURI(title)}/${
            CONSTANTS.TRAILER
          }/${videoId}`;
  
          // Navigate to the trailer path
          fnNavTo.call(this, sTrailerPath);
        } else {
          throw new Error("Unexpected response format");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  
  

  /**
   * Handler for windo scroll
   * @param {Object} oEvent - event handler
   * @returns {undefined}
   */

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      mobileView: window.innerWidth < CONSTANTS.MOBILE_VIEW_THRESHOLD,
    });
  }
  handleImageChange = (newImageUrl) => {
    this.setState({
      homePageD: {
        ...this.state.homePageD,
        value: newImageUrl,
      },
    });
  };
  /**
   * Component Name - SmartTvLayout
   * It returns jsx to be rendered
   * @param null
   * @returns { undefined }
   */

  render() {

     const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 768 && screenWidth <= 1024;
    const isMobileView = screenWidth <= 767
    const { clickedIndex, homePageD, imgs, backdropImages } = this.state;
    const { locale } = this.props;
    const content_typeImg = this.state && this.state.homePageD.contentTypeImg;
    const thumbnailParts = this.state.homePageD.value.split("/");
    const WatchNowButton =  this.props.oPageContent && this.props.oPageContent.data  &&  this.props.oPageContent.data.featured
      .play_watch_trailer;
    const MoreButton =  this.props.oPageContent && this.props.oPageContent.data  &&  this.props.oPageContent.data.featured.more_button;
    let detailsBackground = "";
    if (
      this.props.type == "movie" ||
      this.props.type == "livetv" ||
      this.props.type == "play"
    ) {
      detailsBackground =
        thumbnailParts.slice(0, 3).join("/") +
        "/1920x1080/" +
        thumbnailParts.slice(3, 5).join("/") +
        thumbnailParts.slice(5);
    } else {
      detailsBackground =
        thumbnailParts.slice(0, 3).join("/") +
        "/1920x1080/" +
        thumbnailParts.slice(3, 5).join("/") +
        "/" +
        thumbnailParts.slice(5);
    }
    let imageSource;
    let cdetailsContent;
    if (content_typeImg == "movie") {
      imageSource = Moviesimg;
      cdetailsContent = oResourceBundle.movies;
    } else if (content_typeImg == "series") {
      imageSource = Seriesimg;
      cdetailsContent = oResourceBundle.series;
    } else if (content_typeImg == "livetv") {
      imageSource = LiveTvimg;
      cdetailsContent = oResourceBundle.LiveTv;
    } else if (content_typeImg == "program") {
      imageSource = Programsimg;
      cdetailsContent = oResourceBundle.programs;
    } else if (content_typeImg == "play") {
      imageSource = Playlistimg;
      cdetailsContent = oResourceBundle.plays;
    }
    const dir = common.getDirection(this.props.locale);
    const rtl = dir === "rtl" ? true : false;
    const dots = true;
    return (
      <React.Fragment>
        <>
          <div
            className={
              this.props.locale == "en"
                ? "LayoutD-Container"
                : "LayoutD-containerAr"
            }
          >
            <div
              className={
                this.props.locale == "en"
                  ? "synopsis-title"
                  : "synopsis-titleAr"
              }
            >
              <div
                className={
                  this.props.locale == "en"
                    ? "bottom-left-images"
                    : "bottom-left-imagesAr"
                }
              >
                <img src={imageSource} alt="" className="title-images1" />
                <h3 className="new-banner-contentype1">{cdetailsContent}</h3>
              </div>
              <div
                className={
                  this.props.locale === "en" ? "bottom-left" : "bottom-leftAr"
                }
              >
                {this.state.homePageD.hasSeasonLogo ? (
                  <>
                    <div
                      className={
                        this.props.locale == "en"
                          ? "titleLogoContainer"
                          : "titleLogoContainerAr"
                      }
                    >
                      {!isMobileView && (
                        <img
                          className={
                            this.props.locale === "en"
                              ? "titleLogoImage"
                              : "titleLogoImageAr"
                          }
                          src={this.state.homePageD.hasSeasonLogo}
                          alt=""
                          onLoad={this.handleLogoLoad}
                          width={this.state.seasonLogoDimensions.width}
                          height={ this.state.seasonLogoDimensions.height}
                        />
                      )}
                      {isMobileView && (
                        <img
                          className={
                            this.props.locale === "en"
                              ? "titleLogos"
                              : "titleLogosAr"
                          }
                          src={this.state.homePageD.hasSeasonLogo}
                          alt=""
                          onLoad={this.handleLogoLoad}
                          width={this.state.seasonLogoDimensions.width}
                          height={ this.state.seasonLogoDimensions.height}
                        />
                      )}
                      <h2 className="contentTitleEn">                     
                        {content_typeImg === "movie"
                          ? ""
                          : this.props.locale === "en"
                          ? this.state.homePageD.title
                          : ""}
                      </h2>
                    </div>
                  </>
                ) : (
                  <h1
                    className={
                      this.props.locale === "en"
                        ? "contentTitleEnAR"
                        : "contentTitleArabic"
                    }
                  >
                    {this.state.homePageD.title}
                  </h1>
                )}
              </div>
              {/* {!isMobile ? ( */}
                <div
                  className={
                    this.props.locale == "en"
                      ? "bottom-left-synopsis"
                      : "bottom-left-synopsisAr"
                  }
                >
                  {this.state.homePageD.synopsis}
                </div>
              {/* ) : (
              <>
               {this.state.homePageD.synopsis}</>
              )} */}

              <div
                className={
                  this.props.locale == "en"
                    ? "watchMore_Button"
                    : "watchMore_ButtonAr"
                }
              >
                {WatchNowButton ? (
                  <div>
                    <button
                      className={
                        this.props.locale === "en" ? "watchNow" : "watchNowAr"
                      }
                      onClick={
                        content_typeImg === "movie" ||
                        content_typeImg === "livetv" ||
                        content_typeImg === "play"
                          ? this.playButtonClick
                          : this.state.homePageD.WatchTrailer
                          ? this.playButtonClick
                          : this.onClickTrialers
                      }
                    >
                      {content_typeImg === "movie" ||
                      content_typeImg === "livetv" ||
                      content_typeImg === "play"
                        ? oResourceBundle.play
                        : this.state.homePageD.WatchTrailer
                        ? oResourceBundle.play
                        : oResourceBundle.Watch_Trailer}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {MoreButton ? (
                  <div>
                    <img
                      className={
                        this.props.locale == "en"
                          ? "morebutton"
                          : "morebuttonAr"
                      }
                      src={MoreDetailsIcon}
                      alt=""
                      onClick={this.moreButtonClick}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {homePageD.value && (
              <>
                <div
                  onClick={() => this.moreButtonClick()}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={backdropImages[clickedIndex].image}
                    className="homePageSlider"
                    alt="Dynamic Image"
                  />
                  <div
                    className={
                      locale === "en" ? "Carousel_Image" : "Carousel_ImageAr"
                    }
                  >
                    {backdropImages.map((content, i) => (
                      <div
                        className={
                          locale === "en" ? "thumbnail" : "thumbnailAr"
                        }
                        key={i}
                      >
                        <div
                          className={`carouselClicked ${
                            clickedIndex === i ? "clicked" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleClick(i, i);
                          }}
                          alt={`${content}-${i}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {!isMobileView ? <div className="gradient1"></div> : ""}
            {isMobileView ? (
              <div
                className={
                  this.props.locale == "en" ? "gradient2" : "gradient2Ar"
                }
              ></div>
            ) : (
              ""
            )}
            {ENABLE_BANNER_ADVERTISEMENT && (
              <div className="firstFoldedBanner" id="maindiv"></div>
            )}
          </div>
        </>
      </React.Fragment>
    );
  }
}

/**
 * Component - SmartTvLayout
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = (state) => {
  return {
    sCountryCode: state.sCountryCode,
    isMENARegion: state.isMENARegion,
    isUserSubscribed: state.bIsUserSubscribed,
    aUserPlayList: state.aUserPlayList,
    oVideoDetailContent: state.oVideoDetailContent,
    aRelatedVideos: state.aRelatedVideos,
    TrailerVideos: state.TrailerVideos,
    aRelatedVideosWithType: state.aRelatedVideosWithType,
    oUserResumablesObject: state.oUserResumablesObject,
    loginDetails: state.loginDetails,
    isPremium: state.isPremium,
    locale: state.locale,
    oPageContent: state.oPageContent,
    homePageD: state.homePageD,
    locale: state.locale,
    videoDetailLoading: state.videoDetailLoading,
    sCountryCode: state.sCountryCode,
    isMENARegion: state.isMENARegion,
    bPageViewSent: state.bPageViewSent,
  };
};
/**
 * method that maps state to props.
 * Component - SmartTvLayout
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = (dispatch) => {
  //dispatch action to redux store
  return {
    fnFetchBucketSelectedItemContent: (
      sLanguageCode,
      sVedeoId,
      sVideoType,
      sCountry,
      sBucketTitle,
      fnSuccess
    ) => {
      dispatch(
        actionTypes.fnFetchBucketSelectedItemContent(
          sLanguageCode,
          sVedeoId,
          sVideoType,
          sCountry,
          sBucketTitle,
          fnSuccess
        )
      );
    },
    fnUpdateResumePagePath: (sPath) => {
      dispatch(actionTypes.fnUpdateResumePagePath(sPath));
    },

    fnFetchSelectedVideoItemContent: (
      sLanguageCode,
      sVedeoId,
      sVideoType,
      sCountry,
      fnSuccess
    ) => {
      dispatch(
        actionTypes.fnFetchSelectedVideoItemContent(
          sLanguageCode,
          sVedeoId,
          sVideoType,
          sCountry,
          fnSuccess
        )
      );
    },
    fnResetVideoItemContent: () => {
      dispatch(
        actionTypes.updateSelectedVideoItemContent({
          oVideoContent: null,
          aRelatedVideos: null,
          aRelatedVideosWithType: null,
          aUserPlayList: [],
        })
      );
    },
    fnFetchTrailerForVideos: (
      sLanguageCode,
      sVideoId,
      sVideoType,
      sCountry,
      fnTrailersSuccess
    ) => {
      dispatch(
        actionTypes.fnFetchTrailerForVideos(
          sLanguageCode,
          sVideoId,
          sVideoType,
          sCountry,
          fnTrailersSuccess
        )
      );
    },
    fnUnmountTrailers: () => {
      dispatch(actionTypes.fnUnmountTrailers());
    },
    fnPageViewSent: () => {
      dispatch(actionTypes.fnPageViewSent());
    },
    fnFetchPageContent: (
      sLocale,
      sCategoryId,
      sCountryCode,
      fnMyPlayListLoginFailure,
      apiFailure
    ) => {
      dispatch(
        actionTypes.fnFetchPageContent(
          sLocale,
          sCategoryId,
          sCountryCode,
          fnMyPlayListLoginFailure,
          apiFailure
        )
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SmartTvLayoutD)
);
